import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-xl-12" }
const _hoisted_3 = { class: "row mb-6" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "required" }
const _hoisted_6 = { class: "row mt-3" }
const _hoisted_7 = { class: "required" }
const _hoisted_8 = { class: "row mt-5" }
const _hoisted_9 = { class: "col-md-12" }
const _hoisted_10 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      class: "form",
      onSubmit: _ctx.onSumbit
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Avatar, {
              avatar: _ctx.form.thumbnail,
              onRemoveImage: _ctx.removeImage,
              onChangeImage: _ctx.changeImage
            }, null, 8, ["avatar", "onRemoveImage", "onChangeImage"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("idescription")), 1),
          _createVNode(_component_Field, {
            modelValue: _ctx.form.description,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.description) = $event)),
            type: "text",
            name: "description",
            autocomplete: "off",
            class: "W-100",
            rules: _ctx.isNameRequired
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_ErrorMessage, {
            name: "name",
            class: "text-danger"
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("iurl")), 1),
          _createVNode(_component_Field, {
            modelValue: _ctx.form.url,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.url) = $event)),
            type: "text",
            name: "url",
            autocomplete: "off",
            class: "W-100"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                ref: "submitButton",
                class: "custom-btn"
              }, _toDisplayString(_ctx.$t("btnSave")), 513)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}