
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import Avatar from "@/components/avatar/avatar.vue";
import { ElNotification } from "element-plus";
import {useStore} from "vuex";

interface FormCatalog {
  id?: number;
  description: string;
  thumbnail: string;
  url: string;
}

export default defineComponent({
  name: "DashboardForm",
  components: {
    Avatar,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      description: "",
      thumbnail: "",
      url: "",
    });
    const logo = ref(undefined) as any;

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/dashboards/" + props.entityId).then(({ data }) => {
          form.value = { ...data };
        });
        store.commit("setLoadingStatus", false);
      }
    });

    const changeImage = (event) => {
      logo.value = event[0];
    };

    const removeImage = () => {
      logo.value = "";
    };

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        if (logo.value) {
          let fm = new FormData();
          fm.append("description", form.value.description);
          fm.append("thumbnail", logo.value);
          fm.append("url", form.value.url);
          const resp = (await ApiService.postAttach("/api/dashboards", fm))
            .data;
          emit("success", resp);
          resetForm();
          submitButton.value?.removeAttribute("data-kt-indicator");
        } else {
          store.commit("setLoadingStatus", false);
          ElNotification({
            title: "Error",
            message: t("ravatar"),
            type: "error",
          });
        }
      } catch (error) {
        store.commit("setLoadingStatus", false);
        emit("success", []);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      let fm = new FormData();
      fm.append("description", form.value.description);
      if (logo.value) {
        fm.append("thumbnail", logo.value);
      }
      fm.append("url", form.value.url);
      ApiService.postAttach("/api/dashboards/update/" + props.entityId, fm)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          emit("success", []);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      logo,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
      changeImage,
      removeImage,
    };
  },
});
