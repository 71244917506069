import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "create-header d-sm-flex flex-row justify-content-between" }
const _hoisted_3 = { class: "mt-auto" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_basic_select = _resolveComponent("table-basic-select")!
  const _component_DashboardForm = _resolveComponent("DashboardForm")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("dashboard")), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_el_row, { justify: "end" }, {
            default: _withCtx(() => [
              (_ctx.canCreate)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "custom-btn mb-4",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_1",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.createElement && _ctx.createElement(...args)))
                  }, _toDisplayString(_ctx.$t("btnNew")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                type: "text",
                class: "find-btn w-sm-200px",
                placeholder: `${_ctx.$t('isearch')}`,
                onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
              }, null, 40, _hoisted_4), [
                [_vModelText, _ctx.search]
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_table_basic_select, {
        class: "mt-5",
        headers: _ctx.header,
        data: _ctx.dataObject,
        buttons: _ctx.tableButtons,
        total: _ctx.dataObject.length,
        "can-update": _ctx.canUpdate,
        "can-delete": _ctx.canDelete,
        "can-show": _ctx.canShow,
        onEventEmitButton: _ctx.actionsButton
      }, null, 8, ["headers", "data", "buttons", "total", "can-update", "can-delete", "can-show", "onEventEmitButton"]),
      (_ctx.dialogVisible)
        ? (_openBlock(), _createBlock(_component_el_dialog, {
            key: 0,
            modelValue: _ctx.dialogVisible,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
            width: "30%",
            "custom-class": "dialog-container",
            title: 
        _ctx.modalMode === 'add' ? _ctx.$t('mt_dashboard_add') : _ctx.$t('mt_dashboard_edit')
      
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DashboardForm, {
                "entity-id": _ctx.itemID,
                onSuccess: _ctx.resetModal
              }, null, 8, ["entity-id", "onSuccess"])
            ]),
            _: 1
          }, 8, ["modelValue", "title"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showDelete,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showDelete) = $event)),
      title: _ctx.$t('iconfirmdialog'),
      width: "30%",
      "custom-class": "dialog-container"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDelete = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("btnCancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.deleteElement
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("btnConfirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("iconfirm")), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}