
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "Avatar",
  emits: ["removeImage", "changeImage"],
  props: {
    avatar: {
      type: String,
      default: "/media/defaults/avatar.png",
    },
    principal_div_class: {
      type: String,
      default: "col-lg-12",
    },
    only_view: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const avatar_temporal = ref(props.avatar);

    const previewAvatar = (event) => {
      const [file] = event.target.files;
      if (file) {
        avatar_temporal.value = URL.createObjectURL(file);
        emit("changeImage", [file]);
      }
    };

    const removeImage = () => {
      avatar_temporal.value = "/media/defaults/avatar.png";
      emit("removeImage");
    };

    watch(
      () => props.avatar,
      (first) => {
        avatar_temporal.value = first;
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMounted(() => {});

    return {
      previewAvatar,
      removeImage,
      avatar_temporal,
    };
  },
});
